import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { Figure, StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import { StyledTopicLink } from "scmp-app/components/content/content-topic-link/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  ${theme.breakpoints.only("mobile")} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div``;

export const StyledEntityLink = styled(EntityLink)`
  position: relative;

  display: block;

  color: #000000;
`;

export const TopicLabel = styled.div`
  ${StyledTopicLink} {
    color: #4585ff;
    font-weight: 700;
    font-size: 13px;
    font-family: ${fontRobotoCondensed};
    text-transform: uppercase;
  }
`;

export const Headline = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 12px;
  align-items: flex-start;

  margin-block-end: 12px;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 25.2px;

  ${theme.breakpoints.up("tablet")} {
    font-size: 22px;
    line-height: 30.8px;
  }
`;

export const Summary = styled.div`
  margin-block-end: 12px;

  color: #6f6f6f;
  font-size: 14px;
  line-height: 18.2px;
`;

export const SponsorTagContainer = styled.div`
  max-inline-size: 240px;
`;

export const ActionBar = styled.div`
  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }
  time {
    color: #999999;
    font-size: 12px;
  }
`;

export const CoverImage = styled.div`
  ${Figure} picture {
    aspect-ratio: 3/2;

    img {
      aspect-ratio: inherit;
    }
  }

  ${StyledFigcaption} {
    display: none;

    ${theme.breakpoints.up("tablet")} {
      display: block;

      line-height: 14px;
    }
  }

  ${theme.breakpoints.only("mobile")} {
    margin-block-end: 12px;
  }
`;

export const LiveTagContainer = styled.div``;

export const SectionContainer = styled.div``;

export const Header = styled.div`
  ${TopicLabel} {
    margin-block-end: 8px;
  }
  ${LiveTagContainer} {
    margin-block-end: 8px;
  }
  ${SectionContainer} {
    margin-block-end: 8px;
  }
`;
