import { FollowType, notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { author } from "shared/data";

import type { Props as FollowButtonProps } from "scmp-app/components/follow-button";
import { FollowButton } from "scmp-app/components/follow-button";
import type { entityFollowButtonBase$key } from "scmp-app/queries/__generated__/entityFollowButtonBase.graphql";

export type Props = Omit<FollowButtonProps, "entityIds" | "name" | "type"> & {
  reference: entityFollowButtonBase$key;
};

export const EntityFollowButton: FunctionComponent<Props> = ({
  reference: reference_,
  ...props
}) => {
  const entity = useFragment(
    graphql`
      fragment entityFollowButtonBase on BaseWithApplicationAndUrlAlias {
        __typename
        ... on Section {
          entityId
          name
        }
        ... on Author {
          entityId
          name
          disableFollow
        }
        ... on Topic {
          entityId
          name
          disableFollow
        }
      }
    `,
    reference_,
  );

  switch (entity.__typename) {
    case "%other":
      return null;
    case "Author":
      if (
        notEmpty(entity.disableFollow) ||
        author.advertisingPartner.entityId === entity.entityId
      ) {
        return null;
      }
      break;
    case "Topic":
      if (entity.disableFollow) return null;
      break;
  }

  const mapTypenameToFollowType = () => {
    switch (entity.__typename) {
      case "Author":
        return FollowType.Author;
      case "Section":
        return FollowType.Section;
      case "Topic":
        return FollowType.Topic;
    }
  };

  return (
    <FollowButton
      entityIds={[entity.entityId]}
      name={entity.name}
      type={mapTypenameToFollowType()}
      {...props}
    />
  );
};

EntityFollowButton.displayName = "EntityFollowButton";
