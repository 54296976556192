import { FollowType, PageType } from "@product/scmp-sdk";

import type {
  FollowEvent,
  TriggerPoint,
} from "scmp-app/components/tracking/google-analytics-4/types";

import type { FollowButtonSource } from "./types";

type ComputeGa4CustomizedParametersArguments = {
  currentPageType: PageType;
  entityIds: string | string[];
  source?: FollowButtonSource;
  type: FollowType;
};

type GetGa4TriggerPointArguments = {
  currentPageType: PageType;
  source?: FollowButtonSource;
};

export function computeGa4CustomizedParameters({
  currentPageType,
  entityIds,
  source,
  type,
}: ComputeGa4CustomizedParametersArguments): Omit<
  FollowEvent["customized_parameters"],
  "action_type" | "package_name" | "user_type"
> {
  return {
    ...(type === FollowType.Author ? { author_id: entityIds[0] } : {}),
    ...(type === FollowType.Section ? { section_id: entityIds[0] } : {}),
    ...(type === FollowType.Topic ? { topic_id: entityIds[0] } : {}),
    follow_type: (() => {
      switch (type) {
        case FollowType.Author:
          return "author";
        case FollowType.Section:
          return "section";
        case FollowType.Topic:
          return "topic";
      }
    })(),
    internal_campaign: "",
    trigger_point: getGa4TriggerPoint({ currentPageType, source }),
  };
}

const getGa4TriggerPoint = ({ currentPageType, source }: GetGa4TriggerPointArguments) => {
  if (currentPageType === PageType.Homepage) {
    return "homepage_click";
  }

  switch (source) {
    case "Article_bottom":

    case "Article_top":

    case "Author":
    case "Homepage_click":
    case "Section":
    case "Topic":
    case "Trending_topics":
      return source.toLowerCase() as TriggerPoint;
    case "Article_topic_footer":
      return "article_bottom";
    case "Trending Topics":
      return "trending_topics";

    default:
      return source;
  }
};
