import type { FollowType } from "@product/scmp-sdk";
import { FollowButtonDisplay, FollowButtonOutline, FollowButtonVariant } from "@product/scmp-sdk";
import { useCallback, useState } from "react";
import type { FunctionComponent, ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as FollowButtonProps } from "scmp-app/components/entity-follow-button";
import { useUserService } from "scmp-app/lib/user-service/hooks";
import type { followButtonBase$key } from "scmp-app/queries/__generated__/followButtonBase.graphql";

import type { StyledMobileEntityFollowButtonProps } from "./styles";
import {
  Container,
  StyledDesktopEntityFollowButton,
  StyledMobileEntityFollowButton,
} from "./styles";

type ButtonStyleAttribute = {
  display: FollowButtonDisplay;
  outline: FollowButtonOutline;
};

type ButtonStyleProps = {
  desktop: ButtonStyleAttribute;
  mobile: ButtonStyleAttribute;
};

type Props = Omit<FollowButtonProps, "reference"> & {
  buttonVariant?: StyledMobileEntityFollowButtonProps["$buttonVariant"];
  children?: ReactNode;
  className?: string;
  reference: followButtonBase$key;
  type: FollowType;
};

export const HomeFollowButton: FunctionComponent<Props> = ({
  buttonVariant,
  children,
  className,
  onClick,
  reference,
  source,
  type,
  ...props
}) => {
  const data = useFragment(
    graphql`
      fragment followButtonBase on BaseWithApplicationAndUrlAlias {
        ...entityFollowButtonBase
        entityId
      }
    `,
    reference,
  );

  const { checkIsFollowed } = useUserService();

  const [isFollowButtonJustClicked, setIsFollowButtonJustClicked] = useState(false);
  const isFollowed = checkIsFollowed(type, data.entityId);

  const handleClick = useCallback(
    (isActionFollow: boolean) => {
      setIsFollowButtonJustClicked(true);
      onClick?.(isActionFollow);
    },
    [onClick],
  );

  const getButtonStyle = (): ButtonStyleProps => {
    switch (buttonVariant) {
      case "square":
        return {
          desktop: {
            display: FollowButtonDisplay.BlueSquareIcon,
            outline: FollowButtonOutline.Plain,
          },
          mobile: {
            display: FollowButtonDisplay.BlueSquareIcon,
            outline: FollowButtonOutline.Plain,
          },
        };

      default:
        return {
          desktop: {
            display: FollowButtonDisplay.BlueSquareIcon,
            outline: FollowButtonOutline.Plain,
          },
          mobile: {
            display: FollowButtonDisplay.BluePlusIcon,
            outline: FollowButtonOutline.Outlined,
          },
        };
    }
  };

  if (!isFollowButtonJustClicked && isFollowed) return null;

  const buttonStyle = getButtonStyle();
  return (
    <Container className={className}>
      <StyledMobileEntityFollowButton
        {...props}
        {...buttonStyle.mobile}
        $buttonVariant={buttonVariant}
        disabled={isFollowed}
        isLoadingEnabled
        onClick={handleClick}
        reference={data}
        source={source}
        variant={FollowButtonVariant.Homepage}
      />
      <StyledDesktopEntityFollowButton
        {...props}
        {...buttonStyle.desktop}
        disabled={isFollowed}
        isLoadingEnabled
        onClick={handleClick}
        reference={data}
        source={source}
        variant={FollowButtonVariant.Homepage}
      />
      {children}
    </Container>
  );
};

HomeFollowButton.displayName = "HomeFollowButton";
