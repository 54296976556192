/**
 * @generated SignedSource<<a56b2182868c59355a4dabeae8cce705>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type topStoriesItemContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"helpersUseContentSponsorContent" | "homePrimaryContentItemContent" | "homeSecondaryContentItemContent">;
  readonly " $fragmentType": "topStoriesItemContent";
};
export type topStoriesItemContent$key = {
  readonly " $data"?: topStoriesItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"topStoriesItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "topStoriesItemContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homePrimaryContentItemContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "homeSecondaryContentItemContent"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersUseContentSponsorContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Sponsor",
              "kind": "LinkedField",
              "name": "sponsor",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sponsorTagSponsor"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sponsorType",
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMainTopicContent",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topics",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "2a7b447a8f62e8688aae94cc74b7bce8";

export default node;
