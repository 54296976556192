import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed, theme, transientOptions } from "@product/scmp-sdk";

import { ContentCoverImage } from "scmp-app/components/content/content-cover-image";
import { Figure, StyledFigcaption } from "scmp-app/components/content/content-cover-image/styles";
import { SponsorHeadline as ContentSponsorHeadlineSpan } from "scmp-app/components/content/content-headline/styles";
import { SectionLink } from "scmp-app/components/content/content-sections/styles";
import { StyledTopicLink } from "scmp-app/components/content/content-topic-link/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { HomeFollowButton } from "scmp-app/components/home/follow-button";
import { StyledLi } from "scmp-app/components/schema-render/content/styles";

const itemBorderStyle = css`
  margin-block-end: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);
`;

export const Container = styled.div`
  display: grid;
  grid:
    "leading-image" min-content
    "primary" min-content
    "rest" min-content
    / 1fr;

  ${theme.breakpoints.up("tablet")} {
    grid:
      "leading-image leading-image" min-content
      "primary rest" min-content
      / 1fr calc((100% - 64px) / 3);

    column-gap: 32px;
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  color: #000000;
`;

export const TopicContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Topic = styled.div`
  ${StyledTopicLink} {
    color: #001246;
    font-weight: 700;
    font-size: 15px;
    font-family: ${fontRobotoCondensed};
    font-style: normal;
    line-height: 18px;
  }
`;

export const SectionContainer = styled.div``;

export const Section = styled.div`
  ${SectionLink} {
    color: #4585ff;
    font-size: 13px;
  }
`;

export const LeadingImage = styled.div`
  grid-area: leading-image;

  margin-block-end: 12px;

  ${theme.breakpoints.up("tablet")} {
    position: relative;

    margin-block-end: 24px;

    overflow: hidden;
    aspect-ratio: 250/99;
    ${Figure} {
      position: absolute;

      block-size: auto;
    }
  }
`;

export const StyledContentCoverImage = styled(ContentCoverImage)``;

export const StyledHomeFollowButton = styled(HomeFollowButton)``;

export const Headline = styled.div`
  margin-block-end: 12px;

  font-weight: 700;
  font-size: 18px;
  font-family: ${fontMerriweather};
  line-height: 25.2px;

  ${ContentSponsorHeadlineSpan} {
    font-weight: 700;
    font-style: normal;
    line-height: 22.4px;
  }
`;

export const Summary = styled.div`
  margin-block-end: 12px;

  color: #6f6f6f;
  font-size: 14px;
  line-height: 18.2px;

  &:empty {
    margin: 0;
  }
`;

export const SubHeadline = styled.div`
  color: #6f6f6f;
  font-size: 14px;
  line-height: 18.2px;

  ${StyledLi} {
    margin-block-end: 12px;
  }

  &:empty {
    margin: 0;
  }
`;

export const ActionBar = styled.div`
  time {
    color: #999999;
    font-size: 12px;
  }
`;

type StyledCoverEntityLinkProps = {
  $isSponsorArticle?: boolean;
};
export const StyledCoverEntityLink = styled(EntityLink, {
  ...transientOptions,
})<StyledCoverEntityLinkProps>`
  display: ${props => (props.$isSponsorArticle ? "block" : "none")};

  margin-block-start: 12px;

  ${theme.breakpoints.up("tablet")} {
    display: block;
  }
`;

export const CoverImage = styled.div`
  inline-size: 120px;
  block-size: 80px;

  ${StyledFigcaption} {
    ${theme.breakpoints.up("tablet")} {
      line-height: 14px;
    }
  }
`;

export const Primary = styled.div`
  grid-area: primary;
  ${itemBorderStyle};
  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 0;
    padding-block-end: 0;

    border: none;
  }
`;

export const Rest = styled.div`
  grid-area: rest;
`;

export const TopicLabel = styled.div`
  ${StyledTopicLink} {
    color: #4585ff;
    font-weight: 700;
    font-size: 13px;
    font-family: ${fontRobotoCondensed};
    text-transform: uppercase;
  }
`;

export const LiveTagContainer = styled.div``;

export const Header = styled.div`
  ${LiveTagContainer} {
    margin-block-end: 8px;
  }
  ${SectionContainer} {
    margin-block-end: 12px;
  }
  ${TopicContainer} {
    margin-block-end: 8px;
  }
  ${TopicLabel} {
    margin-block-end: 8px;
  }
`;
